.contactForm {
  margin-top: -20px;
  margin-left: -25px;
  margin-right: -25px;
  padding: 10px;
}

.name,
.yourEmail,
.yourMessage {
  border-top: solid 1px #434141;
  border-radius: 0px;
  border-bottom: none;
  border-right: none;
  border-left: none;
  background-color: #f1efee;
  width: 100vw;
  font-size: 15px;
  font-family: "Andale Mono";
  font-weight: lightest;
  text-transform: uppercase;
  padding: 20px 0px;
  text-indent: 35px;
}
.yourMessage {
  border-bottom: solid 1px #434141;
  margin-bottom: 15px;
}

.pleaseSubmit {
  text-align: start;
  margin-left: 0px;
  border: none;
  color: #434141;
  background-color: #f1efee;
  width: 100vw;
  font-size: 15px;
  font-family: "Andale Mono";
  font-weight: lightest;
  text-transform: uppercase;
  text-indent: 27px;
}

.name:focus,
.yourEmail:focus,
.yourMessage:focus,
.pleaseSubmit:focus {
  outline: none;
}

input::placeholder {
  color: #434141;
}
