.headerWrap {
  border-bottom: solid 1.5px #434141;
  padding: 0px;
  position: fixed;
  width: 100%;
  background-color: #f1efee;
}

.ercTitleLink {
  text-decoration: none;
}

h1 {
  color: #434141;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 50px;
  letter-spacing: 7px;
  padding: 0px;
  margin: 10px;
}

/* @media (min-width: 900px) {
  .headerWrap {
    border: none;
    padding: 0px;
    position: fixed;
    width: 15%;
    background-color: transparent;
    z-index: 10;
  }

  h1 {
    color: #434141;
    border-right: solid 3px #434141;
    background-color: #f6f5f0;
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    padding: 300px 40px 20px 0px;
    margin: 0px;
    width: 50%;
    height: 100vh;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-size: 70px;
    letter-spacing: 7px;
    z-index: 0;
  }
}

*/
