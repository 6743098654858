.footerWrap {
  border-bottom: solid 2px #434141;
  margin-bottom: 0px;
}

.logo {
  color: #434141;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 50px;
  letter-spacing: 7px;
  padding: 0px;
  margin: 10px;
  text-align: center;
}

.ig {
  display: flex;
  align-items: center;
}

@media (min-width: 900px) {
  .footerWrap {
    width: 100%;
  }
  .ercFooterTitle {
    padding: 0px 0px 15px 0px;
  }
}
