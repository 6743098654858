.accordion-parent {
  border: solid, 0px, #434141;
  background-color: #f1efee;
  margin-top: -25px;
}

.accordion {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.accordion .toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 10px 15px;
  font-weight: 100;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.accordion .toggle:hover .direction-indicator {
  color: #dbff13;
}

.accordionLabel {
  font-family: "Andale Mono";
  color: #434141;
  font-size: 20px;
  padding: 0px;
  margin-bottom: 10px;
}

.accordion .toggle .direction-indicator {
  display: flex;
  color: #6495ed;
  align-items: center;
  justify-content: center;
  width: 15px;
  font-weight: 100;
  font-size: 10px;
}

.accordion .content-parent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in;
  color: #434141;
  border-bottom: 1.5px solid #434141;
}

.accordion.toggled .content-parent {
  max-height: 1000px;
  transition: max-height 0.2s ease-in;
}

.accordion .content {
  padding: 10px 15px;
}
