.aboutText {
  color: #434141;
  font-family: "Andale Mono";
  font-weight: lightest;
  margin-top: -20px;
  margin-bottom: 50px;
}

p {
  font-size: 10px;
  margin: 10px 20px;
}

.highlight {
  background-color: #dbff13;
}

.title {
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: cornflowerblue;
}

@media (min-width: 900px) {
  .aboutText {
    padding-bottom: 100px;
    max-width: 50%;
  }
}
