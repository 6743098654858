main {
  align-items: center;
  justify-content: center;
}
.imageWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  max-width: 360px;
  min-width: 360px;
  margin: 0 auto;
}

.imageWrap .single {
  width: 80px;
  cursor: pointer;
}

.imageWrap .single img {
  max-width: 90%;
}

.largeImageWrap {
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  background-color: #f1efee;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-left: -15px;
  padding: 40px 0px;
  z-index: 9;
}

.fullScreenImage img {
  max-width: 70%;
  display: block;
  margin: 0 auto;
}

.titles p {
  text-align: center;
  font-size: 10px;
}

@media (min-width: 900px) {
  .imageWrap {
    display: flex;
    align-self: center;
    gap: 10px;
    max-width: 500px;
    min-width: 1000px;
  }

  .imageWrap .single {
    width: 200px;
    cursor: pointer;
    margin: 10px;
  }

  .imageWrap .single img {
    max-width: 100%;
  }

  .largeImageWrap {
    overflow: hidden;
    background-color: #f1efee;
    display: flex;
    justify-content: center;
    margin-top: 78.9px;
    padding-top: 100px;
    z-index: 9;
    width: 100%;
  }

  .fullScreenImage img {
    margin-top: -40px;
    max-height: 140%;
  }
}
.update {
  margin: 200px 10px;
}
h2 {
  font-family: "andale mono";
  text-transform: uppercase;
  font-size: 30px;
  color: #434141;
}
